import React, { useState, useEffect } from 'react';
import { ViewButton } from './style';
import { Modal, View, Text, Dimensions, ScrollView } from 'react-native';
import FlatListMultipleSelection from '../../ClickCard/FlatListMultipleSelection';
import { Calendar } from 'react-native-calendars';
import Button from '../../../components/Button';
import { SmallText, SubTitle } from '../../../config/theme/globalStyles';
import TextInput from '../../../components/TextInput';
import { emailValidator, endDateValidator, selectedProductsValidator } from "../../../utils";
import { HelperText } from "react-native-paper";

const screenWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get('window').height;

const CreateUserModal = ({ products, visible, onClose, onSave }) => {
  const [email, setEmail] = useState({ value: "", error: "" });
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [endDate, setEndDate] = useState({ value: "", error: "" });
  const [loadingSave, setLoadingSave] = useState(false);
  const [productsError, setProductsError] = useState("");

  useEffect(() => {
    if (visible) {
      setSelectedProducts(products.map(product => ({ ...product, selected: false })));
      setEmail({ value: "", error: "" });
      setEndDate({ value: "", error: "" });
      setProductsError("");
    }
  }, [visible]);

  const handleDateSelect = (date) => {
    const today = new Date();
    const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const selectedDate = new Date(date.timestamp);
    const offset = selectedDate.getTimezoneOffset();
    selectedDate.setMinutes(selectedDate.getMinutes() + offset);
    const selectedDateStart = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

    if (selectedDateStart >= todayStart) {
      setEndDate({ value: selectedDate, error: "" });
    } else {
      setEndDate({ ...endDate, error: "Data de término deve ser no futuro" });
    }
  };

  const validateInputs = () => {
    const emailError = emailValidator(email.value);
    const endDateError = endDateValidator(endDate.value);
    const selectedProductsError = selectedProductsValidator(selectedProducts);

    setEmail({ ...email, error: emailError });
    setEndDate({ ...endDate, error: endDateError });
    setProductsError(selectedProductsError);

    return !emailError && !endDateError && !selectedProductsError;
  };

  const renderArrow = (direction) => (
    <View>
      <Text>{direction === 'left' ? '<' : '>'}</Text>
    </View>
  );

  const handleSave = async () => {
    setLoadingSave(true);

    if (validateInputs()) {
      try {
        await onSave({ email: email.value, selectedProducts, endDate: endDate.value });
        onClose();
      } catch (error) {
        console.error('Erro ao salvar usuário:', error);
      } finally {
        setLoadingSave(false);
      }
    } else {
      setLoadingSave(false);
    }
  };

  return (
    <Modal
      animationType="fade"
      visible={visible}
      onRequestClose={onClose}
      transparent={true}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        }}
      >
        <View
          style={{
            maxWidth: screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9,
            backgroundColor: 'white',
            maxHeight: windowHeight * 0.8,
            borderRadius: 10,
            padding: 5,
            alignItems: 'center',
          }}
        >
          <ScrollView
            contentContainerStyle={{ paddingHorizontal: '1.5rem', flexGrow: 1 }}
            showsVerticalScrollIndicator={true}
            style={{ width: '100%', height: '100%' }}
          >
            <SubTitle padding="1rem 0rem" color="black">
              Adicionar um(a) aluno(a) manualmente
            </SubTitle>
            <TextInput
              color="black"
              backgroundColor="white"
              value={email.value}
              onChangeText={(text) => setEmail({ value: text, error: '' })}
              label="Email"
              placeholder="Email do aluno"
              error={!!email.error}
              autoCapitalize="none"
              autoCompleteType="email"
              textContentType="emailAddress"
              keyboardType="email-address"
            />
            <HelperText type="error" visible={!!email.error}>
              {email.error}
            </HelperText>
            <FlatListMultipleSelection
              items={selectedProducts}
              setItems={setSelectedProducts}
              smallText="Produtos Selecionados:"
              footerText="Selecione os produtos:"
            />
            {productsError ? (
              <HelperText type="error">{productsError}</HelperText>
            ) : null}
            <SmallText color="black">
              Data de fim de acesso:
            </SmallText>
            <Calendar
              onDayPress={handleDateSelect}
              markedDates={
                endDate.value
                  ? {
                      [endDate.value.toISOString().split('T')[0]]: {
                        selected: true,
                        selectedColor: '#2B93ED',
                      },
                    }
                  : {}
              }
              renderArrow={renderArrow}
            />
            <HelperText type="error" visible={!!endDate.error}>
              {endDate.error}
            </HelperText>
            <SmallText color='black'>A senha padrão gerada é: app@2024</SmallText>
            <ViewButton style={{ marginTop: '1rem' }}>
              <Button
                title="Salvar"
                isLoading={loadingSave}
                onPress={handleSave}
                colorbutton={'#3FC380a0'}
                colortitle={'white'}
              />
              <Button
                title="Cancelar"
                onPress={onClose}
                colorbutton={'#FA5D5Da0'}
                margin="0rem 0rem 0rem 1rem"
                colortitle={'white'}
              />
            </ViewButton>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default CreateUserModal;
