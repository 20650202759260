import React, { useCallback, useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";
import { useForm } from "react-hook-form";
import { Main } from "./style";
import { View } from "react-native";
import { useGetUserInfo, useUpdatedUserInfo } from "../../api/MyAccount";
import { getAuth } from "firebase/auth";
import { updateProfile } from "firebase/auth";
import { useCustomTheme } from '../../contexts/useCustomTheme';
import {
  useUpdatedCustomizationInfo,
} from '../../api/Brand';

export const useMyAccount = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [termsOfUseUrl, setTermsOfUseUrl] = useState('');
  const [webUrl, setWebUrl] = useState('');
  const [salesPageUrl, setSalesPageUrl] = useState('');
  const [iosUrl, setIosUrl] = useState('');
  const [androidUrl, setAndroidUrl] = useState('');
  const [formEmbed, setFormEmbed] = useState('');

  const auth = getAuth();
  const user = auth.currentUser;
  const { data: dataUserInfo, isLoading: isLoadingUserInfo } = useGetUserInfo(user.uid);

  const {
    customInfo: customizationInfo,
  } = useCustomTheme();

  //UPDATE USER ADMIN INFOS
  const { mutateAsync: updatedUserInfo, isPending: isPendingUpdatedUserInfo } = useUpdatedUserInfo(dataUserInfo && dataUserInfo.id);
  //UPDATE INFOS
  const { mutateAsync: updatedCustomInfo } = useUpdatedCustomizationInfo();

  const isUrlValid = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const filterUndefinedValues = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined)
    );
  };

  //UPDATE INFOS
  useEffect(() => {
    if (customizationInfo) {
      setTermsOfUseUrl(customizationInfo.termsOfUseUrl);
      setSalesPageUrl(customizationInfo.salesPageUrl);
      setWebUrl(customizationInfo.webUrl);
      setIosUrl(customizationInfo.iosUrl);
      setAndroidUrl(customizationInfo.androidUrl);
      setFormEmbed(customizationInfo.formEmbed);
    }
  }, [customizationInfo]);

  useEffect(() => {
    // Cria um objeto com todos os campos
    const customizationData = {
      termsOfUseUrl: termsOfUseUrl,
      webUrl: webUrl,
      salesPageUrl: salesPageUrl,
      iosUrl: iosUrl,
      androidUrl: androidUrl,
      formEmbed: formEmbed
    };

    // Filtra campos undefined
    const filteredCustomizationData = filterUndefinedValues(customizationData);

    // Só chama updatedCustomInfo se houver dados válidos para enviar
    if (Object.keys(filteredCustomizationData).length > 0) {
      updatedCustomInfo(filteredCustomizationData);
    }
  }, [termsOfUseUrl, salesPageUrl, iosUrl, androidUrl, formEmbed, webUrl]);

  useEffect(() => {
    setValue("Email", user.email);
    if (dataUserInfo) {
      setValue("Nome_Completo", user.displayName || "");

      setValue("appInformation.IOSUrl", dataUserInfo.appInformation?.IOSUrl || "");
      setValue("appInformation.androidUrl", dataUserInfo.appInformation?.androidUrl || "");
      setValue("appInformation.appName", dataUserInfo.appInformation?.appName || "");
      setValue("appInformation.webUrl", dataUserInfo.appInformation?.webUrl || "");
      // Adicionando "/Login" ao final de webUrl
      const webUrl = dataUserInfo.appInformation?.webUrl || "";
      setValue("appInformation.loginUrl", webUrl ? `${webUrl}/Login` : "");
      setValue("appInformation.supportContact", dataUserInfo.appInformation?.supportContact || "");

      setValue("hasAppInfo", true);
    }
  }, [dataUserInfo, setValue]);

  const onSubmit = useCallback(async (data) => {
    const newData = { ...data };
    const updatedData = {
      ...newData,
    };
    await updateProfile(user, { displayName: getValues("Nome_Completo") });
    await updatedUserInfo(updatedData);
  }, []);

  const MobileOrDesktopForm = useCallback(({ children }) => {
    const { width } = useViewport();
    const breakpoint = 1250;
    return width < breakpoint ? (
      <Main style={{ flexDirection: "column", alignItems: "center" }}>
        {children}
      </Main>
    ) : (
      <Main style={{ flexDirection: "row", alignItems: "flex-start" }}>
        {children}
      </Main>
    );
  }, []);

  const MobileOrDesktopContainer = useCallback(({ children }) => {
    const { width } = useViewport();
    const breakpoint = 1250;
    return width < breakpoint ? (
      <View
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </View>
    ) : (
      <View
        style={{
          height: "92vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </View>
    );
  }, []);


  return {
    MobileOrDesktopForm,
    onSubmit,
    control,
    errors,
    handleSubmit,
    isLoadingUserInfo,
    isPendingUpdatedUserInfo,
    setValue,
    selectedCountry,
    setSelectedCountry,
    MobileOrDesktopContainer,
    isUrlValid,
    auth,
    termsOfUseUrl,
    setTermsOfUseUrl,
    salesPageUrl,
    setSalesPageUrl,
    webUrl,
    setWebUrl,
    iosUrl,
    setIosUrl,
    androidUrl,
    setAndroidUrl,
    formEmbed,
    setFormEmbed
  };
};
