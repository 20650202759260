import styled from 'styled-components/native';


export const Container = styled.View`
  flex: 1;
  background-color: ${(props) => props.background};
`;

export const ContainerLayout = styled.View`
  flex: 1;
  flex-direction: row;
  padding: 24px;
`;

export const ContainerInputs = styled.View`
  width: 65%;
`;

export const ContainerMockup = styled.View`
  width: 35%;
`;

export const Input = styled.TextInput`
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
`;

export const ButtonContainer = styled.View`
  width: 100%;
  padding: 10px;
`;

export const ErrorMessage = styled.Text`
  color: red;
  margin-top: 8px;
  text-align: center;
`;

export const CharacterCount = styled.Text`
  font-size: 12px;
  color: gray;
  text-align: right;
  margin-top: 4px;
`;

export const InfoMessage = styled.Text`
  font-size: 14px;
  color: gray;
  margin-bottom: 8px;
`;
