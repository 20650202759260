import styled from "styled-components/native";
import THEME from "../../config/theme";

export const Container = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    flexDirection: "column",
    flexBasis: "auto",
    height: "auto",
    backgroundColor: "#d3d3d3"
  },
})``;

export const Box = styled.View`
  margin: 0 1.25rem 1.25rem 1.25rem;
  background: #fff;
  border-radius: 0.5rem;
  padding-bottom: 1.25rem;
`;

export const Main = styled.View`
  display: flex;
  justify-content: center;
`;

export const FormContainer = styled.View`
  width: -webkit-fill-available;
  max-width: 40rem;
  padding: 1.25rem;
  min-height: 15rem;
`;

export const InputWrapper = styled.View`
  margin-bottom: 0.8rem;
`;

export const Heading = styled.Text`
  text-align: start;
  margin: 0;
  padding: 0;
  color: black;
`;

export const ErrorText = styled.Text`
  color: red;
  margin-top: 5px;
`;