import React from "react";
import { View } from "react-native";
import Button from "../../../components/Button";

import { Image } from "../style";

const ViewImage = (props) => {
    const { showModal, img, backgroundImage } = props;

    const image = backgroundImage?.url || img;

    const content = (
        <Image source={image} resizeMode="cover">
            <View
                style={{
                    backgroundColor: "rgba(0,0,0,0.7)",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Button
                    title={"Alterar Capa do Módulo"}
                    onPress={showModal}
                ></Button>
            </View>
        </Image>
    );

    return (
        <View
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                borderRadius: 10,
                overflow: "hidden",
            }}
        >
            {content}
        </View>
    )
};

export default ViewImage;